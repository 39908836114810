import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import { useForm } from "../../../hooks/useForm";
import Swal from "sweetalert2";

const CashIn = () => {
    const endpoint = "cashin";
    const parent = "Cash";
    const [t] = useTranslation("global");
    const title = t('cashIN.cashin');
    const prefix = "cashin";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filters, setFilters] = useState({
        serial: []
    });

    const [totalCashIn, setTotalCashIn] = useState(0);

    const {
        filterText,
        filteredItems,
        filterInitDate,
        filterEndDate,
        filterSelect,
        setFilterText,
        setInitFilterDate,
        setEndFilterDate,
        setFilterSelect,
        download
    } = useForm(
        data,
        endpoint,
        [{
            'field': 'cashin_id'
        }, {
            'field': 'cashin_in'
        }, {
            'field': 'cashin_date',
            'type': 'isDate'
        }, {
            'field': 'machine_serial_label' ,
            'type': 'isSelect'
        }]);


    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        // Calcular el total cada vez que los datos o los filtros cambien
        calculateTotalCashIn();
    }, [filteredItems, filterText, filterInitDate, filterEndDate, filterSelect]);

    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            case 'download':
                download();
                break;
            case 'clear':
                setFilterText('');
                setInitFilterDate('');
                setEndFilterDate('');
                setFilterSelect('');
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        const confirmButtonColor = action === 'active' ? 'green' : 'red';
        const confirmationMessage = action === 'active' ? t('activateAlert.activateRecord') : t('activateAlert.deactivateRecord');
        Swal.fire({
            title: confirmationMessage,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('activateAlert.confirm'),
            confirmButtonColor: confirmButtonColor,
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                const status = action === 'active' ? 1 : 0;
                const body = { action: 'updateStatus', status };
                handlerApp.setLoader(true);
                api.put(endpoint + "/" + id, body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const deleteRow = (id) => {
        Swal.fire({
            title: `${t('deleteAlert.deleteRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('deleteAlert.delete')}`,
            confirmButtonColor: "red",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpoint + '/' + id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const calculateTotalCashIn = () => {
        const total = filteredItems.reduce((accumulator, item) => {
            return accumulator + item.cashin_in;
        }, 0);
    
        // Formatear el total como dólar
        const formattedTotal = total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    
        setTotalCashIn(formattedTotal);
    };

    const columns = [
        {
            id: 'cashin_id',
            name: 'id',
            selector: row => row.cashin_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'machine_serial',
            name: t('cashIN.machine'),
            selector: row => row.machine_serial + " - " + (row.machine_label || 'N/A'),
            sortable: true
        },
        {
            id: 'cashin_in',
            name: t('cashIN.cashin'),
            selector: row => handlerApp.formatCurrency(row.cashin_in),
            sortable: true
        },
        {
            id: 'cashin_date',
            name: t('cashIN.date'),
            selector: row => handlerApp.getDate(row.cashin_date),
            sortable: true
        },
        {
            name: t("action"),
            cell: (row) =>         
                    <div>
                        <button className="btn-table-actions view" title="view" onClick={() => handlerAction(row.cashin_id, 'view')}>
                            <span className="material-symbols-outlined">visibility</span>
                        </button>
                    </div>
             ,
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const uniqueSerialOptions = (data) => {
        const uniqueOptions = [];
        const seen = new Set();
      
        data.forEach((item) => {
          const option = `${item.machine_serial} - ${item.machine_label || 'N/A'}`;
      
          if (!seen.has(option)) {
            uniqueOptions.push(option);
            seen.add(option);
          }
        });
      
        return uniqueOptions;
      };

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
            .then(response => {
                if (response.success) {
                    // Obtén las opciones únicas de machine_serial y machine_label
                    const uniqueOptions = uniqueSerialOptions(response.data);

                    // Reformatea las opciones para que coincidan con el formato del BoldSelect
                    const formattedOptions = uniqueOptions.map((option) => ({
                    code: option,
                    name: option,
                    }));

                    

                    // Actualiza el estado de los filtros con las opciones formateadas
                    setFilters({
                    ...filters,
                    serial: formattedOptions,
                    });
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{t("cashIN.sub_title")}</p>
                </section>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("cashIN.filters")}</h4>
                <div className="row">
                    <div className="col-md-3">
                        <BoldSelect title={t("cashIN.machine")} name="serial" onChange={e => setFilterSelect(e.target.value)} value={filterSelect} options={filters.serial}></BoldSelect>
                    </div>
                    <div className="col-md-2">
                        <input
                            className="form-control"
                            type="datetime-local"
                            name="cashin_date_init"
                            id="cashin_date_init"
                            onChange={e => setInitFilterDate(e.target.value)}
                            value={filterInitDate}
                            title={t("cashIN.start date")}
                        />
                    </div>
                    <div className="col-md-2">
                        <input
                            className="form-control"
                            type="datetime-local"
                            name="cashin_date_end"
                            id="cashin_date_end"
                            onChange={e => setEndFilterDate(e.target.value)}
                            value={filterEndDate}
                            title={t("cashIN.final date")}
                        />
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-2 text-center align-middle" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <button
                            className="btn-table-actions me-5"
                            onClick={() => { handlerAction(null, 'clear') }}
                            title="Limpiar Filtros"
                        >
                            <span className="material-symbols-outlined">
                                cleaning_services
                            </span>
                        </button>
                        
                        <button
                            className="btn-table-actions"
                            onClick={() => { handlerAction(null, 'refresh') }}
                            title="Recargar"
                        >
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                        
                    </div>
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <div className="dataTable">
                <DataTable
                    columns={columns}
                    data={
                        filteredItems.toSorted((a, b) => {
                            return b.cashin_id - a.cashin_id; // Orden descendente de actionlog_id (números)
                        })
                    }
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    // subHeader
                    // subHeaderComponent={subHeaderComponentMemo}
                    selectableRows
                    persistTableHead
                    subHeader 
                    subHeaderComponent={<h4 className="col-md-12 text-sm-end">{t("cashIN.total")} {totalCashIn}</h4> }
                />
            </div>
        </div>
    )
}

export default CashIn;